@media only screen 
   and (max-width : 767px) {
    .home * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
    }
    
    .home{
        padding: 0 10%;
    }
    
    .hero{
        padding-top: 64px;
        padding-bottom: 0px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        text-align: center;
    }
    
    .top{
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 6px;
        color: #18AD00;
        text-transform: uppercase;
        padding: 0px 0px 24px 6px;
    }
    
    .headline{
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 1.1;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
    }
    
    @keyframes text {
        0% {
            opacity: 0;
            transform: translateY(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .subheadline{
        font-size: 16px;
        line-height: 1.6;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.4s;
    }
    
    .sectioncta{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #fff;
        border: 1px solid #18AD00;
        background-color: #18AD00;
        padding: 8px 24px;
        border-radius: 32px;
        margin-top: 48px;
        
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.8s;
    }
    
    .sectioncta:hover{
        background-color: #fff;
        color: #18AD00;
    }
    
    .hero .image{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0px 0px 0px;
    }
    
    .hero .image img{
        position: relative;
        display: block;
        max-width: 100%;
        padding: 8px;
        animation: image-animate 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.2s;
        opacity: 0;
    }
    
    @keyframes image-animate {
        0% {
            opacity: 0;
            transform: translateX(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .about, .products .content, .services{
        padding: 0px 5%;
    }
    
    .section1{
        color: #18AD00;
        text-transform: uppercase;
        font-size: 16px;
        padding: 24px 0px;
        text-align: center;
    }
    
    .section2{
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        padding: 24px 0px;
    }
    
    .section3{
        text-align: center;
        padding-bottom: 32px;
        line-height: 1.6;
    }
    
    .section4{
        text-align: center;
    }
    
    .info {
        margin-top: 0px;
        margin-bottom: 16px;
        padding: 32px;
        background-color: white;
        border-radius: 32px;
    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 32px;
    }

    .info>div:nth-child(2){
        border-top: 1px solid #18AD00;
        border-bottom: 1px solid #18AD00;
        padding: 32px 0px;
    }
    
    .info div p{
        text-align: center;
    }
    
    .infosmall{
        text-transform: uppercase;
        line-height: 1.6;
        font-size: 14px;
    }
    
    .infolarge{
        font-weight: 400;
        font-size: 48px;
        color: #18AD00;
    }
    
    .btn{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #18AD00;
        border: 1px solid rgb(24, 173, 0);
        background-color: 1px solid rgb(24, 173, 0);
        padding: 8px 24px;
        border-radius: 32px;
        background-color: white;
        margin-bottom: 48px;
    }
    
    .btn:hover{
        background-color: #18AD00;
        color: #fff;
    }
    
    .products .listparent{
        display: flex;
        flex-direction: row;
    }
    
    /* Hide scrollbar */
    .products .list::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
    
    /* Hide scrollbar for Firefox */
    .products .list {
        scrollbar-width: none;
    }
    
    .products .list{
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        gap: 32px;
        overflow-y: auto;
        border-radius: 16px;
    }
    
    .listicon{
        display: none;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: '.'
        'mid'
        '.';
    }
    
    .listicon i{
        padding: 16px;
        grid-area: mid;
        font-size: 32px;
        color: lightgray;
        display: inline-block;
        align-self: center;
    }
    
    .listicon i:hover{
        color: gray;
    }
    
    .listitem{
        min-width: 100%;
        background-color: #F7F8FA;
        border-radius: 16px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    
    .listitem p{
        text-align: center;
    }
    
    .itemimage{
        position: relative;
        margin-bottom: 12px;
    }
    
    .itemimage img{
        display: inline-block;
        width: 100%;
        max-height: 160px;
        object-fit: contain;
    }
    
    .itemname{
        color: #18AD00;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    
    .itemdescription{
        font-size: 14px;
        line-height: 1.8;
    }
    
    .services{
        padding: 24px 5%;
    }
}

@media only screen 
and (min-width : 768px) {
 .home * {
     padding: 0;
     margin: 0;
     border: 0;
     box-sizing: border-box;
 }
 
 .home{
     padding: 0 10%;
 }
 
 .hero{
     padding-top: 96px;
     padding-bottom: 12px;
     display: grid;
     grid-template-columns: 1fr;
     grid-template-rows: auto;
     text-align: center;
 }
 
 .top{
     font-size: 16px;
     font-weight: bold;
     letter-spacing: 6px;
     color: #18AD00;
     text-transform: uppercase;
     padding: 0px 0px 24px 6px;
 }
 
 .headline{
     font-size: 56px;
     font-weight: 600;
     margin-bottom: 24px;
     line-height: 1.1;
     opacity: 0;
     animation: text 1.5s ease 0s 1 normal forwards;
 }
 
 @keyframes text {
     0% {
         opacity: 0;
         transform: translateY(80px);
     }
 
     100% {
         opacity: 1;
         transform: translateY(0);
     }
 }
 
 .subheadline{
     font-size: 16px;
     line-height: 1.6;
     opacity: 0;
     animation: text 1.5s ease 0s 1 normal forwards;
     animation-delay: 0.2s;
 }
 
 .sectioncta{
     display: inline-block;
     line-height: 1.6;
     text-decoration: none;
     color: #fff;
     border: 1px solid #18AD00;
     background-color: #18AD00;
     padding: 8px 24px;
     border-radius: 32px;
     margin-top: 48px;
     
     opacity: 0;
     animation: text 1.5s ease 0s 1 normal forwards;
     animation-delay: 0.6s;
 }
 
 .sectioncta:hover{
     background-color: #fff;
     color: #18AD00;
 }
 
 .hero .image{
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 24px 15% 0px 15%;
 }
 
 .hero .image img{
     position: relative;
     display: block;
     max-width: 100%;
     padding: 8px;
     animation: image-animate 1.5s ease 0s 1 normal forwards;
     animation-delay: 0.8s;
     opacity: 0;
 }
 
 @keyframes image-animate {
     0% {
         opacity: 0;
         transform: translateY(80px);
     }
 
     100% {
         opacity: 1;
         transform: translateX(0);
     }
 }
 
 .about, .products .content, .services{
     padding: 0px 5%;
 }
 
 .section1{
     color: #18AD00;
     text-transform: uppercase;
     font-size: 16px;
     padding: 24px 0px;
     text-align: center;
 }
 
 .section2{
     text-align: center;
     font-size: 40px;
     font-weight: 600;
     padding: 24px 0px;
 }
 
 .section3{
     text-align: center;
     padding-bottom: 32px;
     line-height: 1.6;
 }
 
 .section4{
     text-align: center;
 }
 
 .info {
     margin-top: 16px;
     margin-bottom: 48px;
     padding: 32px 64px;
     background-color: whitesmoke;
     border-radius: 32px;
 
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     gap: 0px;
 }

 .info>div{
    border: 0px;
    padding: 0px;
 }
 
 .info div p{
     text-align: center;
 }
 
 .infosmall{
     text-transform: uppercase;
     line-height: 1.5;
     font-size: 14px;
 }
 
 .infolarge{
     font-weight: 400;
     font-size: 56px;
     color: #18AD00;
 }
 
 .btn{
     display: inline-block;
     line-height: 1.6;
     text-decoration: none;
     color: #18AD00;
     border: 1px solid rgb(24, 173, 0);
     background-color: 1px solid rgb(24, 173, 0);
     padding: 8px 24px;
     border-radius: 32px;
     background-color: white;
     margin-bottom: 48px;
 }
 
 .btn:hover{
     background-color: #18AD00;
     color: #fff;
 }
 
 .products .listparent{
     display: flex;
     flex-direction: row;
 }
 
 /* Hide scrollbar */
 .products .list::-webkit-scrollbar {
     display: none; /* Chrome, Safari */
 }
 
 /* Hide scrollbar for Firefox */
 .products .list {
     scrollbar-width: none;
 }
 
 .products .list{
     padding: 8px;
     display: flex;
     justify-content: flex-start;
     flex-direction: row;
     gap: 32px;
     overflow-y: auto;
     border-radius: 16px;
 }
 
 .listicon{
     display: grid;
     grid-template-columns: 1fr auto 1fr;
     grid-template-areas: '.'
     'mid'
     '.';
 }
 
 .listicon i{
     padding: 16px;
     grid-area: mid;
     font-size: 32px;
     color: lightgray;
     display: inline-block;
     align-self: center;
 }
 
 .listicon i:hover{
     color: gray;
 }
 
 .listitem{
     min-width: 264px;
     background-color: #F7F8FA;
     border-radius: 16px;
     padding: 40px;
     box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
 }
 
 .listitem p{
     text-align: center;
 }
 
 .itemimage{
     position: relative;
     margin-bottom: 12px;
 }
 
 .itemimage img{
     display: inline-block;
     width: 100%;
     max-height: 160px;
     object-fit: contain;
 }
 
 .itemname{
     color: #18AD00;
     font-weight: 400;
     text-transform: uppercase;
     font-size: 16px;
     line-height: 1.5;
     margin-bottom: 16px;
 }
 
 .itemdescription{
     font-size: 14px;
     line-height: 1.8;
 }
 
 .services{
     padding: 48px 20%;
 }
}

@media only screen 
   and (min-width : 1024px) {
    .home * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
    }
    
    .home{
        padding: 0 10%;
    }
    
    .hero{
        padding-top: 96px;
        padding-bottom: 24px;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        text-align: center;
    }
    
    .top{
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 6px;
        color: #18AD00;
        text-transform: uppercase;
        padding: 0px 0px 24px 6px;
    }
    
    .headline{
        font-size: 72px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 1.1;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
    }
    
    @keyframes text {
        0% {
            opacity: 0;
            transform: translateY(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .subheadline{
        font-size: 16px;
        line-height: 1.6;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.2s;
    }
    
    .sectioncta{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #fff;
        border: 1px solid #18AD00;
        background-color: #18AD00;
        padding: 8px 24px;
        border-radius: 32px;
        margin-top: 48px;
        
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.6s;
    }
    
    .sectioncta:hover{
        background-color: #fff;
        color: #18AD00;
    }
    
    .hero .image{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 20% 0px 20%;
    }
    
    .hero .image img{
        position: relative;
        display: block;
        max-width: 100%;
        padding: 8px;
        animation: image-animate 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.8s;
        opacity: 0;
    }
    
    @keyframes image-animate {
        0% {
            opacity: 0;
            transform: translateY(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .about, .products .content, .services{
        padding: 0px 10%;
    }
    
    .section1{
        color: #18AD00;
        text-transform: uppercase;
        font-size: 16px;
        padding: 24px 0px;
        text-align: center;
    }
    
    .section2{
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        padding: 24px 0px;
    }
    
    .section3{
        text-align: center;
        padding-bottom: 32px;
        line-height: 1.6;
    }
    
    .section4{
        text-align: center;
    }
    
    .info {
        margin-top: 16px;
        margin-bottom: 48px;
        padding: 32px 64px;
        background-color: whitesmoke;
        border-radius: 32px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 0px;
    }
   
    .info>div{
       border: 0px;
       padding: 0px;
    }

    .info div p{
        text-align: center;
    }
    
    .infosmall{
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 16px;
    }
    
    .infolarge{
        font-weight: 400;
        font-size: 64px;
        color: #18AD00;
    }
    
    .btn{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #18AD00;
        border: 1px solid rgb(24, 173, 0);
        background-color: 1px solid rgb(24, 173, 0);
        padding: 8px 24px;
        border-radius: 32px;
        background-color: white;
        margin-bottom: 48px;
    }
    
    .btn:hover{
        background-color: #18AD00;
        color: #fff;
    }
    
    .products .listparent{
        display: flex;
        flex-direction: row;
    }
    
    /* Hide scrollbar */
    .products .list::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
    
    /* Hide scrollbar for Firefox */
    .products .list {
        scrollbar-width: none;
    }
    
    .products .list{
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 32px;
        overflow-y: auto;
        border-radius: 16px;
    }
    
    .listicon{
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: '.'
        'mid'
        '.';
    }
    
    .listicon i{
        padding: 16px;
        grid-area: mid;
        font-size: 32px;
        color: lightgray;
        display: inline-block;
        align-self: center;
    }
    
    .listicon i:hover{
        color: gray;
    }
    
    .listitem{
        min-width: 264px;
        background-color: #F7F8FA;
        border-radius: 16px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    
    .listitem p{
        text-align: center;
    }
    
    .itemimage{
        position: relative;
        margin-bottom: 12px;
    }
    
    .itemimage img{
        display: inline-block;
        width: 100%;
        max-height: 160px;
        object-fit: contain;
    }
    
    .itemname{
        color: #18AD00;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    
    .itemdescription{
        font-size: 14px;
        line-height: 1.8;
    }
    
    .services{
        padding: 48px 20%;
    }
}

@media only screen 
and (min-width : 1280px) {
    .home * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
    }
    
    .home{
        padding: 0 10%;
    }
    
    .hero{
        padding-top: 96px;
        padding-bottom: 48px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        grid-gap: 0px 24px;
        text-align: left;
    }
    
    .top{
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 6px;
        color: #18AD00;
        text-transform: uppercase;
        padding: 0px 0px 28px 6px;
    }
    
    .headline{
        font-size: 64px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 1.1;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
    }
    
    @keyframes text {
        0% {
            opacity: 0;
            transform: translateY(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .subheadline{
        font-size: 16px;
        line-height: 1.6;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.4s;
    }
    
    .sectioncta{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #fff;
        border: 1px solid #18AD00;
        background-color: #18AD00;
        padding: 8px 24px;
        border-radius: 32px;
        margin-top: 48px;
        
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.8s;
    }
    
    .sectioncta:hover{
        background-color: #fff;
        color: #18AD00;
    }
    
    .hero .image{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px;
    }
    
    .hero .image img{
        position: relative;
        display: block;
        max-width: 100%;
        padding: 8px;
        animation: image-animate 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.2s;
        opacity: 0;
    }
    
    @keyframes image-animate {
        0% {
            opacity: 0;
            transform: translateX(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .about, .products .content, .services{
        padding: 0px 20%;
    }
    
    .section1{
        color: #18AD00;
        text-transform: uppercase;
        font-size: 16px;
        padding: 24px 0px;
        text-align: center;
    }
    
    .section2{
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        padding: 24px 0px;
    }
    
    .section3{
        text-align: center;
        padding-bottom: 32px;
        line-height: 1.6;
    }
    
    .section4{
        text-align: center;
    }
    
    .info {
        margin-top: 16px;
        margin-bottom: 48px;
        padding: 32px 64px;
        background-color: whitesmoke;
        border-radius: 32px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 0px;
    }

    .info>div{
        border: 0px;
        padding: 0px;
    }
    
    .info div p{
        text-align: center;
    }
    
    .infosmall{
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 16px;
    }
    
    .infolarge{
        font-weight: 400;
        font-size: 64px;
        color: #18AD00;
    }
    
    .btn{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #18AD00;
        border: 1px solid rgb(24, 173, 0);
        background-color: 1px solid rgb(24, 173, 0);
        padding: 8px 24px;
        border-radius: 32px;
        background-color: white;
        margin-bottom: 48px;
    }
    
    .btn:hover{
        background-color: #18AD00;
        color: #fff;
    }
    
    .products .listparent{
        display: flex;
        flex-direction: row;
    }
    
    /* Hide scrollbar */
    .products .list::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
    
    /* Hide scrollbar for Firefox */
    .products .list {
        scrollbar-width: none;
    }
    
    .products .list{
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 32px;
        overflow-y: auto;
        border-radius: 16px;
    }
    
    .listicon{
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: '.'
        'mid'
        '.';
    }
    
    .listicon i{
        padding: 16px;
        grid-area: mid;
        font-size: 32px;
        color: lightgray;
        display: inline-block;
        align-self: center;
    }
    
    .listicon i:hover{
        color: gray;
    }
    
    .listitem{
        min-width: 288px;
        background-color: #F7F8FA;
        border-radius: 16px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    
    .listitem p{
        text-align: center;
    }
    
    .itemimage{
        position: relative;
        margin-bottom: 12px;
    }
    
    .itemimage img{
        display: inline-block;
        width: 100%;
        max-height: 160px;
        object-fit: contain;
    }
    
    .itemname{
        color: #18AD00;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    
    .itemdescription{
        font-size: 14px;
        line-height: 1.8;
    }
    
    .services{
        padding: 48px 20%;
    }
}

@media only screen 
   and (min-width : 1440px) {
    .home * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
    }
    
    .home{
        padding: 0 10%;
    }
    
    .hero{
        padding-top: 96px;
        padding-bottom: 48px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
    }
    
    .top{
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 6px;
        color: #18AD00;
        text-transform: uppercase;
        padding: 0px 0px 24px 6px;
    }
    
    .headline{
        font-size: 72px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 1.1;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
    }
    
    @keyframes text {
        0% {
            opacity: 0;
            transform: translateY(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .subheadline{
        font-size: 16px;
        line-height: 1.6;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.4s;
    }
    
    .sectioncta{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #fff;
        border: 1px solid #18AD00;
        background-color: #18AD00;
        padding: 8px 24px;
        border-radius: 32px;
        margin-top: 48px;
        
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.8s;
    }
    
    .sectioncta:hover{
        background-color: #fff;
        color: #18AD00;
    }
    
    .hero .image{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero .image img{
        position: relative;
        display: block;
        max-width: 100%;
        padding: 8px;
        animation: image-animate 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.2s;
        opacity: 0;
    }
    
    @keyframes image-animate {
        0% {
            opacity: 0;
            transform: translateX(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .about, .products .content, .services{
        padding: 0px 20%;
    }
    
    .section1{
        color: #18AD00;
        text-transform: uppercase;
        font-size: 16px;
        padding: 24px 0px;
        text-align: center;
    }
    
    .section2{
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        padding: 24px 0px;
    }
    
    .section3{
        text-align: center;
        padding-bottom: 32px;
        line-height: 1.6;
    }
    
    .section4{
        text-align: center;
    }
    
    .info {
        margin-top: 16px;
        margin-bottom: 48px;
        padding: 32px 64px;
        background-color: whitesmoke;
        border-radius: 32px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 0px;
    }
   
    .info>div{
       border: 0px;
       padding: 0px;
    }
    
    .info div p{
        text-align: center;
    }
    
    .infosmall{
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 16px;
    }
    
    .infolarge{
        font-weight: 400;
        font-size: 64px;
        color: #18AD00;
    }
    
    .btn{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #18AD00;
        border: 1px solid rgb(24, 173, 0);
        background-color: 1px solid rgb(24, 173, 0);
        padding: 8px 24px;
        border-radius: 32px;
        background-color: white;
        margin-bottom: 48px;
    }
    
    .btn:hover{
        background-color: #18AD00;
        color: #fff;
    }
    
    .products .listparent{
        display: flex;
        flex-direction: row;
    }
    
    /* Hide scrollbar */
    .products .list::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
    
    /* Hide scrollbar for Firefox */
    .products .list {
        scrollbar-width: none;
    }
    
    .products .list{
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 32px;
        overflow-y: auto;
        border-radius: 16px;
    }
    
    .listicon{
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: '.'
        'mid'
        '.';
    }
    
    .listicon i{
        padding: 16px;
        grid-area: mid;
        font-size: 32px;
        color: lightgray;
        display: inline-block;
        align-self: center;
    }
    
    .listicon i:hover{
        color: gray;
    }
    
    .listitem{
        min-width: 320px;
        background-color: #F7F8FA;
        border-radius: 16px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    
    .listitem p{
        text-align: center;
    }
    
    .itemimage{
        position: relative;
        margin-bottom: 12px;
    }
    
    .itemimage img{
        display: inline-block;
        width: 100%;
        max-height: 160px;
        object-fit: contain;
    }
    
    .itemname{
        color: #18AD00;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    
    .itemdescription{
        font-size: 14px;
        line-height: 1.8;
    }
    
    .services{
        padding: 48px 20%;
    }
}

@media only screen 
   and (min-width : 1920px) {
    .home * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
    }
    
    .home{
        padding: 0 10%;
    }
    
    .hero{
        padding-top: 96px;
        padding-bottom: 48px;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        grid-gap: 0px 24px;
    }
    
    .top{
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 6px;
        color: #18AD00;
        text-transform: uppercase;
        padding: 0px 0px 24px 6px;
    }
    
    .headline{
        font-size: 72px;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 1.1;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
    }
    
    @keyframes text {
        0% {
            opacity: 0;
            transform: translateY(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    .subheadline{
        font-size: 16px;
        line-height: 1.6;
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.4s;
    }
    
    .sectioncta{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #fff;
        border: 1px solid #18AD00;
        background-color: #18AD00;
        padding: 8px 24px;
        border-radius: 32px;
        margin-top: 48px;
        
        opacity: 0;
        animation: text 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.8s;
    }
    
    .sectioncta:hover{
        background-color: #fff;
        color: #18AD00;
    }
    
    .hero .image{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .hero .image img{
        position: relative;
        display: block;
        max-width: 100%;
        padding: 8px;
        animation: image-animate 1.5s ease 0s 1 normal forwards;
        animation-delay: 0.2s;
        opacity: 0;
    }
    
    @keyframes image-animate {
        0% {
            opacity: 0;
            transform: translateX(80px);
        }
    
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    .about, .products .content, .services{
        padding: 0px 20%;
    }
    
    .section1{
        color: #18AD00;
        text-transform: uppercase;
        font-size: 16px;
        padding: 24px 0px;
        text-align: center;
    }
    
    .section2{
        text-align: center;
        font-size: 48px;
        font-weight: 600;
        padding: 24px 0px;
    }
    
    .section3{
        text-align: center;
        padding-bottom: 32px;
        line-height: 1.6;
    }
    
    .section4{
        text-align: center;
    }
    
    .info {
        margin-top: 16px;
        margin-bottom: 48px;
        padding: 32px 64px;
        background-color: whitesmoke;
        border-radius: 32px;
    
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        gap: 0px;
    }

    .info>div{
        border: 0px;
        padding: 0px;
    }
    
    .info div p{
        text-align: center;
    }
    
    .infosmall{
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 16px;
    }
    
    .infolarge{
        font-weight: 400;
        font-size: 64px;
        color: #18AD00;
    }
    
    .btn{
        display: inline-block;
        line-height: 1.6;
        text-decoration: none;
        color: #18AD00;
        border: 1px solid rgb(24, 173, 0);
        background-color: 1px solid rgb(24, 173, 0);
        padding: 8px 24px;
        border-radius: 32px;
        background-color: white;
        margin-bottom: 48px;
    }
    
    .btn:hover{
        background-color: #18AD00;
        color: #fff;
    }
    
    .products .listparent{
        display: flex;
        flex-direction: row;
    }
    
    /* Hide scrollbar */
    .products .list::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
    
    /* Hide scrollbar for Firefox */
    .products .list {
        scrollbar-width: none;
    }
    
    .products .list{
        padding: 8px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        gap: 32px;
        overflow-y: auto;
        border-radius: 16px;
    }
    
    .listicon{
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-areas: '.'
        'mid'
        '.';
    }
    
    .listicon i{
        padding: 16px;
        grid-area: mid;
        font-size: 32px;
        color: lightgray;
        display: inline-block;
        align-self: center;
    }
    
    .listicon i:hover{
        color: gray;
    }
    
    .listitem{
        min-width: 320px;
        background-color: #F7F8FA;
        border-radius: 16px;
        padding: 40px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    
    .listitem p{
        text-align: center;
    }
    
    .itemimage{
        position: relative;
        margin-bottom: 12px;
    }
    
    .itemimage img{
        display: inline-block;
        width: 100%;
        max-height: 160px;
        object-fit: contain;
    }
    
    .itemname{
        color: #18AD00;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 16px;
    }
    
    .itemdescription{
        font-size: 14px;
        line-height: 1.8;
    }
    
    .services{
        padding: 48px 20%;
    }
}