.hero {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 12px;
}

.heroImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.heroContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 5px;
  bottom: 10px;
  opacity: 0;
  animation: fadeInContent 1s forwards, moveUp 2s infinite alternate;

}

.heroContent h2 {
  margin: 0;
  color: white;
  animation: fadeInText 1s forwards;
  border-left: 4px solid rgb(96, 196, 50);
  padding: 8px 16px;
  font-size: 48px;
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeInText {
  from {
    color: rgba(255, 255, 255, 0);
  }

  to {
    color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes moveUp {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, -55%);
  }
}

.contactDetails {
  padding: 0px 120px;
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  gap: 48px;
}

.contactDetails>div {
  flex-basis: 50%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.formGroup {
  margin-bottom: 20px;
  margin-bottom: 8px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #555;
}

input,
textarea {
  width: calc(100% - 24px);
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  /* Example shadow with offset, blur, and color */
}

.submitButton{
  width: 100%;
  display: inline-block;
  line-height: 1.6;
  text-decoration: none;
  color: #fff;
  border: 1px solid rgb(24, 173, 0);
  background-color: #18AD00;
  padding: 12px 24px;
  border-radius: 8px;
  margin: 12px 0px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.submitButton:hover{
  background-color: white;
  color: #18AD00;
}

/* .submitButton {
  width: 100%;
  padding: 12px;
  background-color: #106b10;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: #2b9710;
} */

.mapContainer {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  border: 2px solid #ccc;
  background-color: #f0f0f0;
  width: 100%;
  height: 450px;
}

.mapContainer iframe{
  display: inline-block;
  width: 100%;
  height: 100%;
}

.contactDetails h2 {
  color: #18AD00;
  text-transform: uppercase;
  font-size: 16px;
  padding: 24px 0px;
  text-align: center;
  font-weight: normal;
}

/* Tablet styles */
@media only screen and (max-width : 1280px) {
  .contactDetails {
    flex-direction: row;
    /* Reverse the order of flex items */
    align-items: flex-start;
    /* Center align the items */
    gap: 32px;
    padding: 0px 32px;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .hero {
    height: 200px;
    margin-bottom: 0px;
  }

  .heroImage {
    min-height: 320px;
    object-fit: cover;
  }

  .heroContent h2 {
    font-size: 36px;
    /* padding: 10px; */
    text-wrap: nowrap;
  }

  .contactDetails {
    flex-direction: column-reverse;
    /* Reverse the order of flex items */
    align-items: center;
    /* Center align the items */
    gap: 20px;
    padding: 0px 32px;
  }

  .contactDetails>div {
    width: 100%;
  }

  .mapContainer {
    width: 100%;
    height: 300px;
  }

  .form {
    width: 100%;
    margin: 0;
  }

  .contactDetails div h2 {
    font-size: 16px;
  }
}