
.hero {
  position: relative;
  width: 100%;
  height: 300px; 
  overflow: hidden;
  margin-bottom: 12px;
}

.heroImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
}

.active {
  opacity: 1;
}


.heroContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 5px;
  bottom: 10px;
  opacity: 0;
  animation: fadeInContent 1s forwards, moveUp 2s infinite alternate; 
}

.heroContent h2 {
  margin: 0;
  color: white;
  animation: fadeInText 1s forwards; 
  border-left: 4px solid rgb(96, 196, 50);
  padding: 8px 16px ;
  font-size: 48px;
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translate(-50%, -60%); 
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%); 
  }
}

@keyframes fadeInText {
  from {
    color: rgba(255, 255, 255, 0); 
  }
  to {
    color: rgba(255, 255, 255, 0.8); 
  }
}

@keyframes moveUp {
  from {
    transform: translate(-50%, -50%);
  }
  to {
    transform: translate(-50%, -55%);
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 48px 0px;
}

.card {
  background-color: #e9edf5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: calc(33.33% - 20px);
  max-width: 380px;
  text-align: center;
  transition: transform 0.3s ease; 
  animation: fadeInSlideUp 1s ease forwards;
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card:hover {
  transform: translateY(-5px); 
}

.card:last-child {
  margin-right: 0;
}

.card h3 {
  margin-bottom: 10px;
  color: #333;
}

.card p {
  color: #666;
  padding: 26px 16px;
  margin-bottom: 50px; 
}

.styles.cardContainer p {
  margin-bottom: 30px; 
}





.circle {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 0px; 
  background: linear-gradient(45deg, #28a745, #218838);
}

.circle:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease; 
}


.circle h3 {
  padding: 20px;
  color: #fff; 
}

/* Tablet styles */
@media only screen and (max-width : 1280px) {
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px;
    margin: 0 auto;
    padding: 32px 12px; 
  }

  .card{
    max-width: 240px;
  }
}

/* Tablet styles */
@media only screen and (max-width : 1024px) {

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px;
    margin: 0 auto;
    padding: 32px 12px; 
  }
  
  .card {
    width: 80%; 
    max-width: none;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .hero {
    height: 200px;
    margin-bottom: 0px;
  }

  .heroImage {
    min-height: 320px;
    object-fit: cover;
  }

  .heroContent h2 {
    font-size: 36px;
    /* padding: 10px; */
    text-wrap: nowrap;
  }

  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    gap: 20px;
    margin: 0 auto;
    padding: 48px 12px; 
  }
  
  .card {
    width: 80%; 
    max-width: none;
    height: auto; 
  }

  .circle {
    width: 80px;
    height: 80px;
    margin-bottom: 20px; 
  }

  .circle h3 {
    font-size: 12px; 
  }
}