html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* <uniquifier>: Use a unique and descriptive class name */
/* <weight>: Use a value from 100 to 900 */

/* .work-sans-<uniquifier> {
  font-family: "Work Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
