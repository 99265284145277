.navigation {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px 96px;
    justify-content: space-between;
    align-items: center;
}

.navigationsecondary {
    padding: 16px 48px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.navigation a, .navigationsecondary a {
    text-decoration: none;
    color: black;
}

.navigation .logo,  .navigationsecondary .logo{
    display: inline-block;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigation .logo img, .navigationsecondary .logo img {
    display: inline-block;
    height: 32px;
}

.navigationsecondary .togglenav i{
    display: inline-block;
    font-size: 28px;
    padding: 16px;
}

.secondarylinks{
    display: none;
    z-index: 1;
    position: absolute;
    /* display: inline-block; */
    top: 72px;
    right: 48px;
    min-width: auto;
    background-color: white;
    border-radius: 24px;
    padding: 16px 0px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.showsecondarylinks{
    display: inline-block;
}

.secondarylinks a{
    display: block;
    padding: 16px 28px;
    text-align: center;
}

.navigation div a {
    padding: 8px 16px;
}

.navigation .button {
    padding: 10px 24px;
    border: 1px solid #18AD00;
    color: #18AD00;
    border-radius: 32px;
}

.navigation .button:hover {
    background-color: #18AD00;
    color: white;
}

.navigation div a:hover {
    color: #18AD00;
}

@media only screen and (min-width : 768px) {
    .navigation {
        display: flex;
    }
    .navigationsecondary{
        display: none;
    }
}

@media only screen and (max-width : 768px) {
    .navigation {
        display: none;
    }
    .navigationsecondary{
        display: flex;
        padding: 16px 36px;
    }
}

@media only screen and (max-width : 320px) {
    .navigation {
        display: none;
    }
    .navigationsecondary{
        display: flex;
        padding: 12px;
    }
}