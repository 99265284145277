@media only screen and (max-width : 767px) {
    footer *{
        padding: 0px;
        margin: 0px;
        border: 0px;
        box-sizing: border-box;
        line-height: 1.8;
    }
    
    .footer {
        padding: 48px 24px;
        background-color: whitesmoke;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 24px 0px;
        text-align: center;
    }
}

@media only screen and (min-width : 768px) {
    
    .footer {
        padding: 48px 96px;
        background-color: whitesmoke;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 48px 24px;
        text-align: left;
    }
}

@media only screen and (min-width : 1024px) {
    
    .footer {
        padding: 48px 96px;
        background-color: whitesmoke;
        display: grid;
        grid-template-columns: auto auto repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 0px 48px;
        text-align: left;
    }
}

@media only screen and (min-width : 1280px) {
    .footer {
        padding: 48px 96px;
        background-color: whitesmoke;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 24px;
        text-align: left;
    }
}

footer *{
    padding: 0px;
    margin: 0px;
    border: 0px;
    box-sizing: border-box;
    line-height: 1.8;
}

.footer a {
    display: block;
    text-decoration: none;
    color: black;
}

.footer a:hover{
    color: rgb(101, 101, 101);
}

p.head {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 6px;
    color: #18AD00;
    text-transform: uppercase;
    margin-bottom: 24px;
}

p.title {
    color: #18AD00;
    margin-bottom: 16px;
}