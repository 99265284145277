.hero {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 32px;
}

.heroImage {
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s ease-in-out;
}

.active {
  opacity: 1;
}


.heroContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1;
  padding: 10px 20px;
  border-radius: 5px;
  bottom: 10px;
  opacity: 0;
  animation: fadeInContent 1s forwards, moveUp 2s infinite alternate;
}

.heroContent h2 {
  margin: 0;
  color: white;
  animation: fadeInText 1s forwards;
  border-left: 4px solid rgb(96, 196, 50);
  padding: 8px 16px;
  font-size: 48px;
}

@keyframes fadeInContent {
  from {
    opacity: 0;
    transform: translate(-50%, -60%);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeInText {
  from {
    color: rgba(255, 255, 255, 0);
  }

  to {
    color: rgba(255, 255, 255, 0.8);
  }
}

@keyframes moveUp {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, -55%);
  }
}

.serviceCards {
  display: flex;
  flex-direction: row;
  padding: 0 5%;
  margin-top: 64px;
  margin-bottom: 48px;
}

.serviceCol {
  flex-basis: 30%;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;
  margin-right: 32px;
  background-color: #F7F8FA;
}

.serviceCol h3 {
  text-align: center;
  color: #18AD00;
  font-weight: 600;
}

.serviceCol p {
  text-align: center;
  padding: 12px 24px;
  font-size: 14px;
  line-height: 1.6;
}

.service-col img {
  width: 100%;
  border-radius: 10px;
}

@media only screen and (max-width : 768px) {
  .hero {
    height: 200px;
    margin-bottom: 48px;
  }

  .heroImage {
    min-height: 320px;
    object-fit: cover;
  }

  .heroContent h2 {
    font-size: 36px;
    /* padding: 10px; */
    text-wrap: nowrap;
  }

  .serviceCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 32px;
    margin-top: 0px;
    margin-bottom: 48px;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }

  .serviceCol {
    flex-basis: 50%;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0px;
    margin-right: 0px;
    background-color: #F7F8FA;
  }

  .serviceCol h3 {
    text-align: center;
    color: #18AD00;
    font-weight: 600;
  }

  .serviceCol p {
    text-align: center;
    padding: 12px 24px;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 24px;
  }

  .serviceCol img {
    position: relative;
    display: inline-block;
    width:100%;
    border-radius: 10px 10px 0px 0px;
  }
}

@media only screen and (min-width : 768px) {
  .serviceCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 32px;
    margin-top: 0px;
    margin-bottom: 48px;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }

  .serviceCol {
    flex-basis: 50%;
    min-width: 320px;
    max-width: 320px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0px;
    margin-right: 0px;
    background-color: #F7F8FA;
  }
}

@media only screen and (min-width : 1040px) {
  .serviceCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 32px;
    margin-top: 0px;
    margin-bottom: 48px;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }

  .serviceCol {
    max-width: 420px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0px;
    margin-right: 0px;
    background-color: #F7F8FA;
  }
}

@media only screen and (min-width : 1280px) {
  .serviceCards {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0px 32px;
    margin-top: 0px;
    margin-bottom: 48px;
    gap: 32px;
    justify-content: center;
    align-items: center;
  }

  .serviceCol {
    max-width: 320px;
    min-width: 0px;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin-bottom: 0px;
    margin-right: 0px;
    background-color: #F7F8FA;
  }
}